<template>
  <section>
    <div class="content-header">
      <h2>What supports exist for students?</h2>
    </div>
    <div class="content-wrapper">
      <p>You may be wondering what campus resources exist to help students with their career planning and job search.</p>
      <p>While every post-secondary institution will differ in the exact services offered, general career planning and job search resources can be accessed through campus career centres. </p>
      <p>Common services include:</p>
      <ul>
        <li>Career counselling and employment advising</li>
        <li>Job portals</li>
        <li>Job leads</li>
        <li>Networking platforms such as Ten Thousand Coffees</li>
        <li>Resumé templates</li>
        <li>Interview guides and practice tools such as Big Interview, which can be integrated into class assignments</li>
        <li>LinkedIn tip sheets</li>
        <li>Succeeding on the job</li>
      </ul>
      <p>Check out Brainstorm Strategy Group’s <a href="https://www.brainstorm.ca/canadian-campus-career-directory" target="_blank">Campus Career Directory</a>, where users can search for contact information for their campus career centre and/or related career development resources. </p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
